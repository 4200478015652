import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      // sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
      id="our-product"
    >
      <Typography
        sx={{ mt: 5 }}
        variant="h4"
        marked="center"
        align="center"
        component="h2"
      >
        Our Product
      </Typography>
      <Container sx={{ mt: 7, mb: 15, display: "flex", position: "relative" }}>
        <Box
          component="img"
          // src="/static/themes/onepirate/productCurvyLines.png"
          // alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              {/* <Box
                component="img"
                src="/static/themes/onepirate/productValues1.svg"
                alt="suitcase"
                sx={{ height: 55 }}
              /> */}
              <Typography variant="h6" sx={{ my: 5 }}>
                Understanding?
              </Typography>
              <Typography variant="h5">
                {
                  "AlphaTerms’ definitions ensure that you’ll know more of what you’re reading so you can spend less on lawyers. "
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              {/* <Box
                component="img"
                src="/static/themes/onepirate/productValues2.svg"
                alt="graph"
                sx={{ height: 55 }}
              /> */}
              <Typography variant="h6" sx={{ my: 5 }}>
                Signing?
              </Typography>
              <Typography variant="h5">
                {"We provide secure, identity assured signing. "}

                {/* {"your Sundays will not be alike."} */}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              {/* <Box
                component="img"
                src="/static/themes/onepirate/productValues3.svg"
                alt="clock"
                sx={{ height: 55 }}
              /> */}
              <Typography variant="h6" sx={{ my: 5 }}>
                Creation?
              </Typography>
              <Typography variant="h5">
                {"Take advantage of easy-to-use contract templates.  "}
                {/* {"that you will not find anywhere else."} */}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
