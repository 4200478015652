import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Drawer from "@mui/material/Drawer";
import AppBar from "../components/AppBar";
import Toolbar from "../components/Toolbar";
import logo from "../../../images/justlogonobackground.svg";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";

const rightLink = {
  fontSize: 16,
  color: "common.white",
  ml: 3,
};

function AppAppBar() {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div>
      <AppBar position="fixed">
        <Drawer
          sx={{ display: { md: "none", sm: "block" } }}
          anchor="left"
          onClose={() => setMobileNavOpen(false)}
          open={isMobileNavOpen}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
              color: "inherit",
              backgroundColor: "#28282a",
            },
          }}
        >
          <Link
            variant="h6"
            underline="none"
            color="common.white"
            href="#top"
            sx={{ fontSize: 24, mt: 2, mb: 2 }}
          >
            <img src={logo} style={{ height: "52px" }} />
            {" AlphaTerms"}
          </Link>
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            href="#our-product"
            sx={{ ...rightLink, mt: 2, mb: 2 }}
          >
            Our Product
          </Link>
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            href="#about"
            sx={{ ...rightLink, mt: 2, mb: 2 }}
          >
            About
          </Link>
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            href="#testimonials"
            sx={{ ...rightLink, mt: 2, mb: 2 }}
          >
            Testimonials
          </Link>
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            href="#pricing"
            sx={{ ...rightLink, mt: 2, mb: 2 }}
          >
            Pricing
          </Link>
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            href="#contact-us"
            sx={{ ...rightLink, mt: 2, mb: 2 }}
          >
            Contact Us
          </Link>
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            href="/login"
            sx={{ ...rightLink, mt: 2, mb: 2 }}
          >
            {"Log In"}
          </Link>
          <Link
            variant="h6"
            underline="none"
            href="/signup"
            sx={{ ...rightLink, mt: 2, mb: 2, color: "secondary.main" }}
          >
            {"Sign Up"}
          </Link>
        </Drawer>
        <Toolbar
          sx={{
            display: { md: "flex", xs: "none" },
            justifyContent: "space-between",
          }}
        >
          {/* <Box sx={{ flex: 1 }} /> */}
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="#top"
            sx={{ fontSize: 24 }}
          >
            <img src={logo} style={{ height: "52px" }} />
            {" AlphaTerms"}
          </Link>
          <Box sx={{ flex: 1 }} />
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            href="#our-product"
          >
            Our Product
          </Link>
          <Box sx={{ flex: 1 }} />
          <Link color="inherit" variant="h6" underline="none" href="#about">
            About
          </Link>
          <Box sx={{ flex: 1 }} />
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            href="#testimonials"
          >
            Testimonials
          </Link>
          <Box sx={{ flex: 1 }} />
          <Link color="inherit" variant="h6" underline="none" href="#pricing">
            Pricing
          </Link>
          <Box sx={{ flex: 1 }} />
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            href="#contact-us"
          >
            Contact Us
          </Link>
          <Box sx={{ flex: 1 }} />
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            href="https://app.alphaterms.com/login"
            sx={rightLink}
          >
            {"Log In"}
          </Link>
          <Link
            variant="h6"
            underline="none"
            href="https://app.alphaterms.com/signup"
            sx={{ ...rightLink, color: "secondary.main" }}
          >
            {"Sign Up"}
          </Link>
          <IconButton
            sx={{ display: { md: "none", sm: "block" } }}
            color="inherit"
            onClick={() => setMobileNavOpen(true)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Toolbar
          sx={{
            display: { md: "none", sm: "flex" },
            justifyContent: "space-between",
          }}
        >
          {/* <Box sx={{ flex: 1 }} /> */}
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="#top"
            sx={{ fontSize: 24 }}
          >
            <img src={logo} style={{ height: "52px" }} />
            {" AlphaTerms"}
          </Link>
          <Box sx={{ flex: 1 }} />
          <IconButton
            sx={{ display: { md: "none", sm: "block" } }}
            color="inherit"
            onClick={() => setMobileNavOpen(true)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
