import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";
// import { Card, CardContent, CardActions } from "@material-ui/core";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Person1 from "../../../images/personjfif.jfif";
import Person2 from "../../../images/Person2jfif.jfif";
import Person3 from "../../../images/person3.jfif";
import Chip from "@mui/material/Chip";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: "default",
  color: "secondary.main",
  fontWeight: "medium",
};

const image = {
  height: 55,
  my: 4,
};

function ProductHowItWorks() {
  return (
    <>
      <Box
        component="section"
        sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
        id="testimonials"
      >
        <Container
          sx={{
            mt: 10,
            mb: 15,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src="/static/themes/onepirate/productCurvyLines.png"
            alt="curvy lines"
            sx={{
              pointerEvents: "none",
              position: "absolute",
              top: -180,
              opacity: 0.7,
            }}
          />
          <Typography
            variant="h4"
            marked="center"
            component="h2"
            sx={{ mb: 14 }}
          >
            Testimonials
          </Typography>
          <div>
            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>
                <Box sx={item}>
                  <Box
                    component="img"
                    src={Person1}
                    alt="suitcase"
                    sx={image}
                  />
                  <Typography variant="h5" align="center">
                    “Using AlphaTerms has made equity deals a lot easier - our
                    staff talk about how much faster it is doing everything on
                    one platform.”
                    <br />
                    <br />- Corrie Price
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={item}>
                  <Box component="img" src={Person2} alt="graph" sx={image} />
                  <Typography variant="h5" align="center">
                    “I had a contract that I didn’t understand at first, but
                    AlphaTerms clarified the key points and guided my questions
                    to my lawyer.”
                    <br />
                    <br />- Eric Cook
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={item}>
                  <Box component="img" src={Person3} alt="clock" sx={image} />
                  <Typography variant="h5" align="center">
                    “Having templates and signing on the same platform has been
                    a boon for our organization.”
                    <br />
                    <br />- Zayden Phelps
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </div>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            component="a"
            href="https://app.alphaterms.com/signup"
            sx={{ mt: 8 }}
          >
            Get started
          </Button>
        </Container>
      </Box>
      <Box
        component="section"
        sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
        id="pricing"
      >
        <Container sx={{ display: "flex", flexWrap: "wrap" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Card sx={item} variant="outlined">
                <CardContent>
                  <Typography
                    // sx={{ fontSize: 14 }}
                    variant="h5"
                    sx={{ mb: 1 }}
                    gutterBottom
                  >
                    Essentials
                  </Typography>
                  <Typography sx={{ mb: 1.5 }}>
                    Includes Hightlighting, Templates, Signing, and Sending
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    style={{ textDecoration: "line-through" }}
                  >
                    $100/month
                  </Typography>
                  <Typography variant="h4">$50/month</Typography>
                  <br />
                  <Chip label="50% off for the first year!" />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={item} variant="outlined">
                <CardContent>
                  <Typography
                    // sx={{ fontSize: 14 }}
                    variant="h5"
                    sx={{ mb: 1 }}
                    gutterBottom
                  >
                    Basic
                  </Typography>
                  <Typography sx={{ mb: 1.5 }}>
                    Includes Templates, Signing, and Sending
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    style={{ textDecoration: "line-through" }}
                  >
                    $40/month
                  </Typography>
                  <Typography variant="h4">$20/month</Typography>
                  <br />
                  <Chip label="50% off for the first year!" />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default ProductHowItWorks;
