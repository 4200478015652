import * as React from "react";
import ProductCategories from "./modules/views/ProductCategories";
import ProductSmokingHero from "./modules/views/ProductSmokingHero";
import AppFooter from "./modules/views/AppFooter";
import ProductHero from "./modules/views/ProductHero";
import ProductValues from "./modules/views/ProductValues";
import ProductHowItWorks from "./modules/views/ProductHowItWorks";
import ProductCTA from "./modules/views/ProductCTA";
import AppAppBar from "./modules/views/AppAppBar";
import withRoot from "./modules/withRoot";
import EULA from "./EULA";
import { useState } from "react";

function Index() {
  const [showEULA, setShowEULA] = useState(false);

  return (
    <React.Fragment>
      {showEULA ? (
        <EULA setShowEULA={setShowEULA} />
      ) : (
        <>
          <AppAppBar />
          <ProductHero />
          <ProductValues />
          <ProductCategories />
          <ProductHowItWorks />
          <AppFooter setShowEULA={setShowEULA} />
        </>
      )}
    </React.Fragment>
  );
}

export default withRoot(Index);
