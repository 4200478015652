import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EULApdf from "../images/AlphaTerms - EULA and Liability Protection.pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EULA = (props) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log(numPages);
    setNumPages(numPages);
  };

  return (
    <>
      <IconButton onClick={() => props.setShowEULA(false)}>
        <ArrowBackIcon />
      </IconButton>
      <Document file={EULApdf} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </>
  );
};

export default EULA;
